/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import { API_CONFIG, CYBER_org_id } from "../Config/config-env.js";
import Loader from "../components/Loader";
/*
import logo from "/redenlace-logo.png";
import logo2 from "../assets/images/logo.png";*/
import { isMobile, getDateCyberFormat } from "../utils/functions";
const Home = () => {
  const { company_code, qr_id } = useParams();

  // Renderiza el componente de clase Home y pasa los parámetros de la URL como props
  return <Class company_code={company_code} qr_id={qr_id} />;
};
var contadorPeticiones = 0;
var maxPeticiones = 50;
class Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      company_code: "",
      qr_id: "",
      amount: 0,
      isValid: true,
      company_name: "",
      isMob: false,
      card_expiry_date: "",
      URLCYBER: "",
      invalid: false,
      loading: true,
      redirect: false,
      invoice_name: "",
      invoice_type: "",
      invoice_number: "",
      qr_image:
        "https://images-quickseller.s3.amazonaws.com/qr-code-loader.gif",
      qr_message: "Generando QR...",
    };
  }

  mostrarFrente = () => {
    const tarjeta = document.querySelector("#tarjeta");
    if (tarjeta.classList.contains("active")) {
      tarjeta.classList.remove("active");
    }
  };

  setValues = (data) => {
    //console.log("Data", data);

    var access_key = data.order.accesskey;
    var profile_id = data.order.profileid;
    var cyberMID = data.order.cybermid;
    var transaction_uuid = data.order.transaction_uuid;
    var signed_field_names =
      "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,merchant_defined_data1,merchant_defined_data4,merchant_defined_data6,merchant_defined_data7,merchant_defined_data9,merchant_defined_data10,merchant_defined_data11,merchant_defined_data12,merchant_defined_data14,merchant_defined_data15,merchant_defined_data17,merchant_defined_data18,merchant_defined_data19,merchant_defined_data87,merchant_defined_data88,merchant_defined_data90,merchant_defined_data91,device_fingerprint_id,override_custom_receipt_page,override_backoffice_post_url";
    if (data.order.cms === "cloudbeds") {
      signed_field_names =
        "access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,merchant_defined_data1,merchant_defined_data4,merchant_defined_data6,merchant_defined_data7,merchant_defined_data9,merchant_defined_data10,merchant_defined_data11,merchant_defined_data12,merchant_defined_data14,merchant_defined_data15,merchant_defined_data17,merchant_defined_data18,merchant_defined_data19,merchant_defined_data87,merchant_defined_data88,merchant_defined_data90,merchant_defined_data91,device_fingerprint_id,override_custom_receipt_page,override_backoffice_post_url,merchant_defined_data46,merchant_defined_data47,merchant_defined_data65,merchant_defined_data72,merchant_defined_data73,merchant_defined_data74,merchant_defined_data76,merchant_defined_data77";
    }
    var unsigned_field_names =
      "card_type,card_number,card_expiry_date,card_cvn";
    var signed_date_time = getDateCyberFormat();

    var tokenData = {};

    var locale = "es";
    var transaction_type = "sale";
    var currency = "BOB";
    var payment_method = "card";

    var bill_to_address_line1 = tokenData.bill_to_address_line1
      ? tokenData.bill_to_address_line1
      : "1295 Charleston Road";
    var bill_to_address_city = tokenData.bill_to_address_city
      ? tokenData.bill_to_address_city
      : "Mountain View";
    var bill_to_address_state = tokenData.bill_to_address_state
      ? tokenData.bill_to_address_state
      : "CA";
    var bill_to_address_country = tokenData.bill_to_address_country
      ? tokenData.bill_to_address_country
      : "US";
    var bill_to_address_postal_code = tokenData.bill_to_address_postal_code
      ? tokenData.bill_to_address_postal_code
      : "94043";

    var date = new Date();
    var mes = "",
      dia = "";
    var anio = date.getFullYear();

    if (date.getMonth() === 0) {
      mes = "01";
    } else if (date.getMonth() < 9) {
      mes = "0" + (date.getMonth() + 1);
    } else {
      mes = date.getMonth() + 1;
    }
    if (date.getDate() < 10) {
      dia = "0" + date.getDate();
    } else {
      dia = date.getDate() + "";
    }
    var fecha = dia + "-" + mes + "-" + anio;

    let dias = "1 dia";
    let cuartos = "1 cuarto";
    let personas = "1 persona";
    let orderDetails = data.orderDetails;

    try {
      let startDate = new Date(orderDetails.startDate);
      let endDate = new Date(orderDetails.endDate);
      let differenceMs = endDate - startDate;
      let differenceDays = differenceMs / (1000 * 60 * 60 * 24);
      if (differenceDays === 1) {
        dias = differenceDays + " día";
      } else {
        dias = differenceDays + " días";
      }
    } catch (e) {}

    try {
      let _cuartos = orderDetails.unassigned.length;
      if (_cuartos === 0) {
        cuartos = "1 cuarto";
      } else {
        if (_cuartos === 1) {
          cuartos = _cuartos + " cuarto";
        } else {
          cuartos = _cuartos + " cuartos";
        }
      }
    } catch (e) {
      cuartos = "1 cuarto";
    }

    try {
      let _personas = Object.keys(orderDetails.guestList).length;
      if (_personas === 0) {
        personas = "1 persona";
      } else {
        let guest = Object.keys(orderDetails.guestList)[0];
        let phoneNumber = orderDetails.guestList[guest].guestPhone;

        tokenData["phonenumber"] = phoneNumber;

        if (_personas === 1) {
          personas = _personas + " persona";
        } else {
          personas = _personas + " personas";
        }
      }
    } catch (e) {}

    var merchant_defined_data1 = tokenData.merchant_defined_data1 || "NO";
    var merchant_defined_data4 = tokenData.merchant_defined_data4 || fecha;
    var merchant_defined_data6 = tokenData.merchant_defined_data6 || "NO";
    var merchant_defined_data7 = data.order.company_name;
    var merchant_defined_data9 = "PAGINA WEB";
    var merchant_defined_data10 = tokenData.merchant_defined_data10 || "SI";
    var merchant_defined_data11 = tokenData.invoicenumber || "0";
    var merchant_defined_data12 = tokenData.phonenumber || "77700123";
    var merchant_defined_data14 = data.order.category;
    var merchant_defined_data15 = tokenData.merchant_defined_data15 || 0;
    var merchant_defined_data17 = tokenData.merchant_defined_data17 || "NO";
    var merchant_defined_data46 = tokenData.merchant_defined_data46 || "NO";
    var merchant_defined_data47 = tokenData.merchant_defined_data47 || "NO";
    var merchant_defined_data65 = "3 Estrellas";
    var merchant_defined_data72 = dias;
    var merchant_defined_data73 = cuartos;
    var merchant_defined_data74 = personas;
    var merchant_defined_data76 = orderDetails.startDate;
    var merchant_defined_data77 = orderDetails.endDate;
    var merchant_defined_data18 = tokenData.merchant_defined_data18 || "";
    var merchant_defined_data19 = tokenData.merchant_defined_data19 || "0";
    var merchant_defined_data87 = tokenData.merchant_defined_data87 || "P-1";
    var merchant_defined_data88 = data.order.company_name;
    var merchant_defined_data90 = data.order.category;
    var merchant_defined_data91 = data.order.amount;

    var override_custom_receipt_page =
      API_CONFIG.base_url + "/pago-satisfactorio/" + data.order.id;
    var override_backoffice_post_url =
      API_CONFIG.base_url + "/cybersource/getResponseBackEnd";

    var device_fingerprint_id = Math.floor(Math.random() * 1e10)
      .toString()
      .padStart(10, "0");
    var reference_number = data.order.order_id;
    var amount = data.order.amount;

    var bill_to_email = tokenData.bill_to_email || "NULL@CYBERSOURCE.COM";
    var bill_to_phone = tokenData.bill_to_phone || "73166538";
    var showText = "hidden";

    this.setState({
      access_key,
      profile_id,
      cyberMID,
      transaction_uuid,
      signed_field_names,
      unsigned_field_names,
      signed_date_time,

      locale,
      transaction_type,
      currency,
      payment_method,

      bill_to_address_line1,
      bill_to_address_city,
      bill_to_address_state,
      bill_to_address_country,
      bill_to_address_postal_code,

      merchant_defined_data1,
      merchant_defined_data4,
      merchant_defined_data6,
      merchant_defined_data7,
      merchant_defined_data9,
      merchant_defined_data10,
      merchant_defined_data11,
      merchant_defined_data12,
      merchant_defined_data14,
      merchant_defined_data15,
      merchant_defined_data17,
      merchant_defined_data46,
      merchant_defined_data47,
      merchant_defined_data65,
      merchant_defined_data72,
      merchant_defined_data73,
      merchant_defined_data74,
      merchant_defined_data76,
      merchant_defined_data77,
      merchant_defined_data18,
      merchant_defined_data19,
      merchant_defined_data87,
      merchant_defined_data88,
      merchant_defined_data90,
      merchant_defined_data91,
      override_custom_receipt_page,
      override_backoffice_post_url,
      device_fingerprint_id,
      reference_number,
      amount,
      bill_to_email,
      bill_to_phone,
      showText,
    });
  };

  getQRBMSCById = async () => {
    const { qr_id } = this.props;
    await axios({
      url: API_CONFIG.base_url + "/getQRBMSCById",
      data: {
        id: qr_id,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.status) {
          this.setState({
            qr_image: "data:image/jpeg;base64," + data.qr,
            qr_message: "¡Escanea este QR y paga!",
          });
        } else {
          this.setState({
            qr_image:
              "https://images-quickseller.s3.amazonaws.com/qr_broken.png",
            qr_message:
              "Hubo un problema generando el QR, aún puede pagar con Tarjeta de crédito/détito",
          });
        }
      })
      .catch(function (error) {
        console.log(">>>>>>>>", error);
      });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      const { qr_id } = this.props;
      return <Navigate to={"/api/v1/pago-satisfactorio/" + qr_id} />;
    }
  };

  saveInvoice = async () => {
    const { qr_id } = this.props;
    var formData = { qr_id: qr_id };
    var form = document.getElementById("formulario-invoice");
    var elements = form.elements;
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if (element.name) {
        formData[element.name] = element.value;
      }
    }
    //console.log(formData);
    this.setState({
      loading: true,
    });
    await axios({
      url: API_CONFIG.base_url + "/updateInvoice",
      data: formData,
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("Respuesta de la API:", data);
      })
      .catch(function (error) {
        console.log(">>>>>>>>", error);
      });

    //var invoiceForm = document.getElementById("invoice-form");
    //invoiceForm.classList.toggle("collapsed");
    this.setState({
      loading: false,
      //toggleButton: true,
    });
  };

  changeHandler = async (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  componentDidMount = async () => {
    //console.log(this.props);
    const { company_code, qr_id } = this.props;

    const formulario = document.querySelector("#formulario-tarjeta");
    const tarjeta = document.querySelector("#tarjeta");
    const btnAbrirFormulario = document.querySelector("#btn-abrir-formulario");
    const contenedor_tarjeta = document.querySelector("#contenedor_tarjeta");

    const nombreTarjeta = document.querySelector("#tarjeta .nombre");
    const cvv = document.querySelector("#tarjeta .cvv");

    let valid = false;

    if (!isMobile()) {
      const pos = 125;
      let time = 500;
      var currentPos = window.scrollY || window.pageYOffset;
      var start = null;
      time = time || 500;

      function animation(currentTime) {
        if (!start) start = currentTime;
        var progress = currentTime - start;
        var newPos = Math.min(
          (progress / time) * (pos - currentPos) + currentPos,
          pos
        );
        window.scrollTo(0, newPos);
        if (progress < time) {
          window.requestAnimationFrame(animation);
        }
      }
      window.requestAnimationFrame(animation);
    } else {
      this.setState({
        isMob: true,
        qr_id_mobile: qr_id,
      });
    }

    await axios({
      url: API_CONFIG.base_url + "/getQrInfo",
      data: {
        qr_id: qr_id,
        company_code: company_code,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
       // console.log("Resp:", data);
        if (!data.status) {
          this.setState({
            invalid: true,
          });
        } else {
          //console.log("SSTAT", data.order.status);
          if (data.order.status.indexOf("PAID") >= 0) {
            this.setState({
              redirect: true,
            });
          } else if (data.order.status === "INVALIDATED") {
            this.setState({
              invalidOrder: true,
            });
          } else {
            valid = true;
            this.setState({
              order_id: data.order.order_id,
              amount: data.order.amount,
              company_name: data.order.company_name,
              image_path: data.order.image_path,

              invoice_name: data.order.invoice_name || "",
              invoice_type: data.order.invoice_type || "",
              invoice_number: data.order.invoice_number || "",
            });
            this.setValues(data);
            this.getQRBMSCById();
          }
        }
        this.setState({
          loading: false,
        });
      })
      .catch(function (error) {
        console.log(">>>>>>>>", error);
      });
    if (valid) {
      var URLCYBER = "https://testsecureacceptance.cybersource.com/silent/pay";
      if (CYBER_org_id === "k8vif92e") {
        URLCYBER = "https://secureacceptance.cybersource.com/silent/pay";
      }

      for (let i = 1; i <= 12; i++) {
        let opcion = document.createElement("option");
        let valor = String(i).padStart(2, "0").slice(0, 2);
        opcion.value = valor;
        opcion.innerText = valor;
        formulario.selectMes.appendChild(opcion);
      }

      // * Select del año generado dinamicamente.
      const yearActual = new Date().getFullYear();
      for (let i = yearActual; i <= yearActual + 8; i++) {
        let opcion = document.createElement("option");
        opcion.value = i;
        opcion.innerText = i;
        formulario.selectYear.appendChild(opcion);
      }

      this.setState({
        company_code: company_code,
        qr_id: qr_id,
        card_expiry_date: "01-" + yearActual,
        URLCYBER: URLCYBER,
      });

      tarjeta.addEventListener("click", () => {
        //tarjeta.classList.toggle('active');
        btnAbrirFormulario.classList.toggle("active");
        formulario.classList.toggle("active");
        contenedor_tarjeta.classList.toggle("active");
      });

      // * Boton de abrir formulario
      btnAbrirFormulario.addEventListener("click", () => {
        btnAbrirFormulario.classList.toggle("active");
        formulario.classList.toggle("active");
        contenedor_tarjeta.classList.toggle("active");
      });

      formulario.card_number.addEventListener("keyup", (e) => {
        let valorInput = e.target.value;

        valorInput = valorInput
          // Eliminamos espacios en blanco
          .replace(/\s/g, "")
          // Eliminar las letras
          .replace(/\D/g, "")
          // Ponemos espacio cada cuatro numeros
          .replace(/([0-9]{4})/g, "$1 ")
          // Elimina el ultimo espaciado
          .trim();
        const numeroTarjeta = document.querySelector("#tarjeta .numero");
        numeroTarjeta.textContent = valorInput;
        const logoMarca = document.querySelector("#logo-marca");
        if (valorInput === "") {
          numeroTarjeta.textContent = "#### #### #### ####";
          logoMarca.innerHTML = "";
        }

        if (valorInput[0] === "4") {
          formulario.card_type.value = "001";
          logoMarca.innerHTML = "";
          const imagen = document.createElement("img");
          imagen.src = "https://images-quickseller.s3.amazonaws.com/visa.png";
          logoMarca.appendChild(imagen);
        } else if (valorInput[0] === "5") {
          formulario.card_type.value = "002";
          logoMarca.innerHTML = "";
          const imagen = document.createElement("img");
          imagen.src =
            "https://images-quickseller.s3.amazonaws.com/mastercard.png";
          logoMarca.appendChild(imagen);
        }

        // Volteamos la tarjeta para que el usuario vea el frente.
        this.mostrarFrente();
      });

      // * Input nombre de tarjeta
      formulario.bill_to_forename.addEventListener("keyup", (e) => {
        let valorInput = e.target.value;
        let valorInput2 = formulario.bill_to_surname.value;

        formulario.bill_to_forename.value = valorInput.replace(/[0-9]/g, "");
        nombreTarjeta.textContent = valorInput + " " + valorInput2;
        firma.textContent = valorInput + " " + valorInput2;
        if (valorInput == "" && valorInput2 == "") {
          nombreTarjeta.textContent = "Juan Perez";
        }
        this.mostrarFrente();
      });

      formulario.bill_to_surname.addEventListener("keyup", (e) => {
        let valorInput = e.target.value;
        let valorInput2 = formulario.bill_to_forename.value;

        formulario.bill_to_surname.value = valorInput.replace(/[0-9]/g, "");
        nombreTarjeta.textContent = valorInput2 + " " + valorInput;
        firma.textContent = valorInput2 + " " + valorInput;
        if (valorInput == "" && valorInput2 == "") {
          nombreTarjeta.textContent = "Juan Perez";
        }
        this.mostrarFrente();
      });

      const firma = document.querySelector("#tarjeta .firma p");
      const mesExpiracion = document.querySelector("#tarjeta .mes");
      const yearExpiracion = document.querySelector("#tarjeta .year");

      // * Select mes
      formulario.selectMes.addEventListener("change", (e) => {
        mesExpiracion.textContent = e.target.value;

        let valorInput = e.target.value;
        let valorInput2 = formulario.selectYear.value;
        formulario.card_expiry_date.value = valorInput + "-" + valorInput2;

        this.mostrarFrente();
      });

      // * Select Año
      formulario.selectYear.addEventListener("change", (e) => {
        yearExpiracion.textContent = e.target.value.slice(2);
        this.mostrarFrente();

        let valorInput = e.target.value;
        let valorInput2 = formulario.selectMes.value;
        formulario.card_expiry_date.value = valorInput2 + "-" + valorInput;
      });

      // * CVV
      formulario.card_cvn.addEventListener("keyup", () => {
        if (!tarjeta.classList.contains("active")) {
          tarjeta.classList.toggle("active");
        }

        formulario.card_cvn.value = formulario.card_cvn.value
          // Eliminar los espacios
          .replace(/\s/g, "")
          // Eliminar las letras
          .replace(/\D/g, "");

        cvv.textContent = formulario.card_cvn.value;
      });

      formulario.addEventListener("submit", function (event) {
        event.preventDefault(); // Previene el envío del formulario por defecto

        console.log("SUB");
        var formData = {};
        var form = document.getElementById("formulario-tarjeta");
        var elements = form.elements;
        for (let i = 0; i < elements.length; i++) {
          var element = elements[i];
          if (element.name) {
            formData[element.name] = element.value;
          }
        }

        var formDataI = { qr_id: qr_id };
        var formI = document.getElementById("formulario-invoice");
        var elementsI = formI.elements;
        for (let i = 0; i < elementsI.length; i++) {
          var elementI = elementsI[i];
          if (elementI.name) {
            formDataI[elementI.name] = elementI.value;
          }
        }
        axios({
          url: API_CONFIG.base_url + "/updateInvoice",
          data: formDataI,
          method: "post",
          dataType: "json",
        })
          .then((response) => response.data)
          .then((data) => {
            console.log("Respuesta de la API:", data);
          })
          .catch(function (error) {
            console.log(">>>>>>>>", error);
          });

        axios({
          url: API_CONFIG.base_url + "/generateCompanySignature",
          data: formData,
          method: "post",
          dataType: "json",
        })
          .then((response) => response.data)
          .then((data) => {
            console.log("Respuesta de la API:", data);
            if (data.status) {
              formulario.signature.value = data.signature;
              formulario.submit();
            } else {
              alert("error");
            }
          })
          .catch(function (error) {
            console.log(">>>>>>>>", error);
          });
      });

      let timeI =
        process.env.REACT_APP_URL_CONFIG === "http://localhost:8600" ? 100 : 7;
      var intervalId = setInterval(this.getQRStatus, timeI * 1000);

      this.setState({ intervalId: intervalId });
    }
  };

  toggleB = () => {
    var invoiceForm = document.getElementById("invoice-form");
    invoiceForm.classList.toggle("collapsed");
    this.setState({ toggleButton: !this.state.toggleButton });
  };
  getQRStatus = async () => {
    const { company_code, qr_id } = this.props;

    contadorPeticiones++;
    // Verificar si se han alcanzado las 100 peticiones
    if (contadorPeticiones >= maxPeticiones) {
      clearInterval(this.state.intervalId);
      this.setState({ forceReload: true });
    }
    await axios({
      url: API_CONFIG.base_url + "/getQRStatus",
      data: {
        qr_id: qr_id,
        company_code: company_code,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
        console.log("Resp:", data);

        if (data.status) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(">>>>>>>>", error);
      });
  };

  downloadQR = async () => {
    const { qr_id } = this.props;
    const originalImage =
      process.env.REACT_APP_URL_CONFIG + "/api/v1/qr-image/" + qr_id;
    const image = await fetch(originalImage);
    // Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  descargarImagen = () => {
    if (document.getElementById("qr_image")) {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        const base64Image = document.getElementById("qr_image").src;
        // Abrir la imagen en una nueva ventana
        window.open(base64Image, "_blank");
      } else {
        // Obtener la imagen en base64
        console.log("img", document.getElementById("qr_image"));
        const base64Image = document.getElementById("qr_image").src;

        // Crear un enlace temporal
        const enlaceTemporal = document.createElement("a");
        enlaceTemporal.href = base64Image;
        enlaceTemporal.target = "_blank";

        // Establecer el nombre de archivo para la descarga
        enlaceTemporal.download = "imagen.jpg";

        // Simular un clic en el enlace para iniciar la descarga
        document.body.appendChild(enlaceTemporal);
        enlaceTemporal.click();
        document.body.removeChild(enlaceTemporal);
      }
    } else {
      console.log("NO READY");
    }
  };

  render() {
    return this.state.invalid ? (
      <>
        {this.renderRedirect()}
        <Loader idLoading={this.state.loading} />
        <h1 className="title"> Pasarela de Pagos </h1>
        <div className="ccontainer">
          <div className="ccards">
            <div className="ccard header-card" style={{ border: 0 }}>
              <h3 style={{ textAlign: "center" }}>
                Lo sentimos, no encontramos su reseva,{" "}
                <a href={"/api/v1/generate-request/" + this.state.company_code}>
                  {" "}
                  haga click aquí{" "}
                </a>
                para intentarlo nuevamente
              </h3>
            </div>
          </div>
        </div>
      </>
    ) : (
      <>
        {this.renderRedirect()}

        {this.state.forceReload && (
          <>
            <div className="loadingBG"></div>

            <div className="loadingBG2">
              <div
                className="loading"
                style={{
                  background: "red",
                  padding: 10,
                  fontSize: "2rem",
                  borderRadius: 15,
                  border: "3px solid red",
                  color: "#fff",
                }}
              >
                Tiempo de espera superado, por favor refresque su ventana
              </div>
            </div>
          </>
        )}

        {this.state.invalidOrder && (
          <>
            <div className="loadingBG"></div>

            <div className="loadingBG2">
              <div
                className="loading"
                style={{
                  background: "red",
                  padding: 10,
                  fontSize: "2rem",
                  borderRadius: 15,
                  border: "3px solid red",
                  color: "#fff",
                  position: "fixed",
                  top: isMobile() ? "5%" : "50%",
                  left: isMobile() ? "5%" : "50%",
                  width: isMobile() ? "90%" : "auto",

                  transform: isMobile() ? "none" : "translate(-50%, -50%)",
                }}
              >
                El monto a pagar de esta reserva ha cambiado, revise su correo
                para revisar los nuevos datos de su reserva
              </div>
            </div>
          </>
        )}

        <Loader idLoading={this.state.loading} />
        <noscript>
          <iframe
            style={{
              width: "100px",
              height: "100px",
              border: 0,
              position: "absolute",
              top: "-50px",
            }}
            src={
              "https://h.online-metrix.net/fp/tags.js?org_id=" +
              { CYBER_org_id } +
              "&session_id=" +
              this.state.cyberMID +
              this.state.device_fingerprint_id
            }
          ></iframe>
        </noscript>

        <h1 class="title" style={{ textAlign: "center" }}>
          <div>
            {!this.state.loading &&
              "Paga tu reserva " + this.state.order_id + " en "}
          </div>
          <div>{" " + this.state.company_name}</div>
        </h1>
        <div class="ccontainer">
          <div class="ccards">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              {/*para bloquear facturacion*/}
              <div class="invoice-form">
                <div
                  id="invoice-form"
                  class=" col-lg-12 col-12 col-sm-12 col-md-12"
                >
                  <form id="formulario-invoice">
                    {/*hasta aqui comentado
                    <div class="text-center">
                      <span class="invoice-title">Datos de Facturación</span>
                    </div>
                      hasta aqui comentado*/}
                    {/*
                    <div class="  ">
                      <label for="invoice_type">Tipo de Documento</label>
                      <select
                        style={{
                          height: 38,
                          padding: "5px 6px",
                          width: "100%",
                          background: "#fff",
                          border: "1px solid #9d9da9",
                          borderRadius: 3,
                        }}
                        name="invoice_type"
                        id="invoice_type"
                      >
                        <option
                          value="1"
                          selected={
                            this.state.invoice_type === "1" ? "selected" : ""
                          }
                        >
                          Cédula de Identidad
                        </option>
                        <option
                          value="2"
                          selected={
                            this.state.invoice_type === "2" ? "selected" : ""
                          }
                        >
                          Cédula de Identidad de Extranjero
                        </option>
                        <option
                          value="3"
                          selected={
                            this.state.invoice_type === "3" ? "selected" : ""
                          }
                        >
                          Pasaporte
                        </option>
                        <option
                          value="4"
                          selected={
                            this.state.invoice_type === "4" ? "selected" : ""
                          }
                        >
                          Otro Documento de Identidad
                        </option>
                        <option
                          value="5"
                          selected={
                            this.state.invoice_type === "5" ? "selected" : ""
                          }
                        >
                          Número de Identificación Tributaria
                        </option>
                      </select>
                    </div>
                    */}

                    {/**desde aqui comentado
                    <div class="row">
                      <div
                        class="col-lg-6 col-12 col-sm-12 col-md-12 "
                        style={{ marginBottom: 10 }}
                      >
                        <label for="invoice_name">Razón Social</label>
                        <input
                          type="text"
                          id="invoice_name"
                          name="invoice_name"
                          maxlength="150"
                          onChange={this.changeHandler}
                          value={this.state.invoice_name}
                          autocomplete="off"
                        />
                      </div>

                      <div class="col-lg-6 col-12 col-sm-12 col-md-12 ">
                        <label for="invoice_number">
                          NIT/Carnet de Identidad
                        </label>
                        <input
                          type="text"
                          id="invoice_number"
                          name="invoice_number"
                          onChange={this.changeHandler}
                          value={this.state.invoice_number}
                          maxlength="30"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="text-center" style={{ marginBottom: 5 }}>
                      <div>
                        <button
                          type="button"
                          onClick={this.saveInvoice}
                          class="invoice-button"
                        >
                          Actualizar Datos
                        </button>
                      </div>
                    </div>
                     hasta aqui comentado*/}
                  </form>
                </div>
                {/**
                <div class="flex-right">
                  {this.state.toggleButton && (
                    <span className="invoice-subtitle">
                      Selecciona tus datos de Facturación
                    </span>
                  )}
                  <span
                    className={this.state.toggleButton ? "plus" : "minus"}
                    id="toggle-button"
                    onClick={this.toggleB}
                  >
                    {!this.state.toggleButton ? "-" : "+"}
                  </span>
                </div>
                */}
              </div>
              {/*hasta aqui facturacion*/}
            </div>

            <div   style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 5,
              }}>
              <div class="ccard header-card">
                <h1 style={{ textAlign: "center" }}>
                  {!this.state.loading
                    ? "¡Elige tu método de pago preferido!"
                    : "Cargando los métodos de pago"}
                </h1>
              </div>
            </div>
            {!this.state.invalidOrder && (
              <>
                <div class="ccard" style={{ textAlign: "center" }}>
                  <p>
                    <h3 style={{ textAlign: "center" }}> QR </h3>
                  </p>
                  <img
                    id="qr_image"
                    name="qr_image"
                    src={this.state.qr_image}
                  />
                  <div class="mensaje">
                    <div style={{ textAlign: "left" }} id="qr_message">
                      {this.state.qr_message === "¡Escanea este QR y paga!" ? (
                        <div id="descarga" class="descarga">
                          <span>1. &#128241;</span>
                          {this.state.isMob ? (
                            <a
                              target="top"
                              href={
                                "/api/v1/qr-image/" + this.state.qr_id_mobile
                              }
                            >
                              Descargar QR
                            </a>
                          ) : (
                            <a
                              id="botonDescargar"
                              download="imagen.jpg"
                              href="#"
                              onClick={this.descargarImagen}
                            >
                              Descargar QR
                            </a>
                          )}{" "}
                          o toma un Screenshot
                        </div>
                      ) : (
                        <span> {this.state.qr_message}</span>
                      )}
                    </div>
                  </div>
                  {this.state.qr_message === "¡Escanea este QR y paga!" && (
                    <>
                      <div
                        className="mensaje"
                        style={{
                          lineHeight: 1,
                          marginBottom: 0,
                          textAlign: "left",
                        }}
                      >
                        <div>
                          2. &#128179;Realiza el pago desde tu App bancaria
                          preferida.
                        </div>
                      </div>

                      <div
                        className="mensaje"
                        style={{
                          lineHeight: 1,
                          marginBottom: 20,
                          textAlign: "left",
                        }}
                      >
                        <div>
                          3. &#9989; ¡Tu reserva se confirmará automáticamente!
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div class="ccard">
                  <p>
                    <h3 style={{ textAlign: "center" }}>
                      Tarjeta Débito/Crédito{" "}
                    </h3>
                  </p>
                  <div id="contenedor_tarjeta" class="contenedor">
                    <section class="tarjeta" id="tarjeta">
                      <div class="delantera">
                        <div class="logo-marca" id="logo-marca"></div>
                        <img
                          src="https://images-quickseller.s3.amazonaws.com/chip-tarjeta.png"
                          class="chip"
                          alt=""
                        />
                        <div class="datos">
                          <div class="grupo" id="numero">
                            <p class="label">Número Tarjeta</p>
                            <p class="numero">#### #### #### ####</p>
                          </div>
                          <div class="flexbox">
                            <div class="grupo" id="nombre">
                              <p class="label">Nombre Tarjeta</p>
                              <p class="nombre">Juan Pérez</p>
                            </div>

                            <div class="grupo" id="expiracion">
                              <p class="label">Expiración</p>
                              <p class="expiracion">
                                <span class="mes">MM</span> /{" "}
                                <span class="year">AA</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="trasera">
                        <div class="barra-magnetica"></div>
                        <div class="datos">
                          <div class="grupo" id="firma">
                            <p class="label">Firma</p>
                            <div class="firma">
                              <p></p>
                            </div>
                          </div>
                          <div class="grupo" id="cvv">
                            <p class="label">cvv</p>
                            <p class="cvv"></p>
                          </div>
                        </div>
                        <p class="leyenda">
                          Los datos de su tarjeta nunca son guardados, esta
                          transacción es administrada por Red Enlace.
                        </p>
                      </div>
                    </section>

                    <div class="contenedor-btn">
                      <button
                        class="btn-abrir-formulario"
                        id="btn-abrir-formulario"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>

                    <form
                      action={this.state.URLCYBER}
                      method="post"
                      id="formulario-tarjeta"
                      class="formulario-tarjeta"
                    >
                      <div class="grupo">
                        <label for="card_number">Número Tarjeta</label>
                        <input
                          type="text"
                          id="card_number"
                          name="card_number"
                          maxlength="16"
                          autocomplete="off"
                        />
                      </div>
                      <div class="grupo">
                        <label for="bill_to_forename">Nombre</label>
                        <input
                          type="text"
                          id="bill_to_forename"
                          name="bill_to_forename"
                          maxlength="30"
                          autocomplete="off"
                        />
                      </div>

                      <div class="grupo">
                        <label for="bill_to_surname">Apellido</label>
                        <input
                          type="text"
                          id="bill_to_surname"
                          name="bill_to_surname"
                          maxlength="30"
                          autocomplete="off"
                        />
                      </div>

                      <div class="grupo flexbox">
                        <div class=" expira" style={{ width: "66.6666%" }}>
                          <label for="selectMes">Expiracion</label>
                          <div class="flexbox">
                            <div class="grupo-select">
                              <select name="mes" id="selectMes">
                                <option disabled selected>
                                  Mes
                                </option>
                              </select>
                              <i class="fas fa-angle-down"></i>
                            </div>
                            <div class="grupo-select">
                              <select name="year" id="selectYear">
                                <option disabled selected>
                                  Año
                                </option>
                              </select>
                              <i class="fas fa-angle-down"></i>
                            </div>
                          </div>
                        </div>

                        <div class=" cvv" style={{ width: "33.3333%" }}>
                          <label for="card_cvn">CVV</label>
                          <input
                            style={{ width: "95%" }}
                            type="password"
                            id="card_cvn"
                            name="card_cvn"
                            maxlength="3"
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <div class="grupo" style={{ paddingTop: "3%" }}>
                        <button type="submit" class="btn-enviar">
                          PAGAR
                        </button>
                      </div>

                      <input
                        type={this.state.showText}
                        name="signature"
                        id="signature"
                        value=""
                      />
                      <input
                        type={this.state.showText}
                        name="access_key"
                        id="access_key"
                        value={this.state.access_key}
                      />
                      <input
                        type={this.state.showText}
                        name="profile_id"
                        id="profile_id"
                        value={this.state.profile_id}
                      />
                      <input
                        type={this.state.showText}
                        name="transaction_uuid"
                        id="transaction_uuid"
                        value={this.state.transaction_uuid}
                      />
                      <input
                        type={this.state.showText}
                        name="signed_field_names"
                        id="signed_field_names"
                        value={this.state.signed_field_names}
                      />
                      <input
                        type={this.state.showText}
                        name="unsigned_field_names"
                        id="unsigned_field_names"
                        value={this.state.unsigned_field_names}
                      />
                      <input
                        type={this.state.showText}
                        name="signed_date_time"
                        id="signed_date_time"
                        value={this.state.signed_date_time}
                      />
                      <input
                        type={this.state.showText}
                        name="locale"
                        id="locale"
                        value={this.state.locale}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data1"
                        value={this.state.merchant_defined_data1}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data4"
                        value={this.state.merchant_defined_data4}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data6"
                        value={this.state.merchant_defined_data6}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data7"
                        value={this.state.merchant_defined_data7}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data9"
                        value={this.state.merchant_defined_data9}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data10"
                        value={this.state.merchant_defined_data10}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data11"
                        value={this.state.merchant_defined_data11}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data12"
                        value={this.state.merchant_defined_data12}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data14"
                        value={this.state.merchant_defined_data14}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data15"
                        value={this.state.merchant_defined_data15}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data17"
                        value={this.state.merchant_defined_data17}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data18"
                        value={this.state.merchant_defined_data18}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data19"
                        value={this.state.merchant_defined_data19}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data87"
                        value={this.state.merchant_defined_data87}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data88"
                        value={this.state.merchant_defined_data88}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data90"
                        value={this.state.merchant_defined_data90}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data91"
                        value={this.state.merchant_defined_data91}
                      />

                      <input
                        type={this.state.showText}
                        name="merchant_defined_data46"
                        value={this.state.merchant_defined_data46}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data47"
                        value={this.state.merchant_defined_data47}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data65"
                        value={this.state.merchant_defined_data65}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data72"
                        value={this.state.merchant_defined_data72}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data73"
                        value={this.state.merchant_defined_data73}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data74"
                        value={this.state.merchant_defined_data74}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data76"
                        value={this.state.merchant_defined_data76}
                      />
                      <input
                        type={this.state.showText}
                        name="merchant_defined_data77"
                        value={this.state.merchant_defined_data77}
                      />

                      <input
                        type={this.state.showText}
                        name="device_fingerprint_id"
                        value={this.state.device_fingerprint_id}
                      />
                      <input
                        type={this.state.showText}
                        name="transaction_type"
                        value={this.state.transaction_type}
                      />
                      <input
                        type={this.state.showText}
                        name="reference_number"
                        value={this.state.reference_number}
                      />
                      <input
                        type={this.state.showText}
                        name="amount"
                        id="amount"
                        value={this.state.amount}
                      />
                      <input
                        type={this.state.showText}
                        name="currency"
                        id="currency"
                        value={this.state.currency}
                      />
                      <input
                        type={this.state.showText}
                        name="payment_method"
                        id="payment_method"
                        value={this.state.payment_method}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_email"
                        value={this.state.bill_to_email}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_phone"
                        value={this.state.bill_to_phone}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_address_line1"
                        value={this.state.bill_to_address_line1}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_address_city"
                        value={this.state.bill_to_address_city}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_address_state"
                        value={this.state.bill_to_address_state}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_address_country"
                        value={this.state.bill_to_address_country}
                      />
                      <input
                        type={this.state.showText}
                        name="bill_to_address_postal_code"
                        value={this.state.bill_to_address_postal_code}
                      />
                      <input
                        type={this.state.showText}
                        name="override_custom_receipt_page"
                        value={this.state.override_custom_receipt_page}
                      />
                      <input
                        type={this.state.showText}
                        name="override_backoffice_post_url"
                        value={this.state.override_backoffice_post_url}
                      />
                      <input
                        type={this.state.showText}
                        value=""
                        name="card_type"
                        id="card_type"
                      />
                      <input
                        type={this.state.showText}
                        value=""
                        name="card_expiry_date"
                        id="card_expiry_date"
                      />
                    </form>
                  </div>
                </div>
              </>
            )}
            {!this.state.loading && (
              <div class="ccard image-card border-top">
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  Monto a Pagar: Bs <b>{this.state.amount.toFixed(2)}</b>{" "}
                </h3>
                <h3 style={{ textAlign: "center" }}>
                  {" "}
                  Nro Reserva: <b>{this.state.order_id}</b>{" "}
                </h3>
                <h3 style={{ textAlign: "center" }}>
                  <span>
                    {" "}
                    Una vez realizado el pago, recibirás un e-mail con la
                    confirmación de tu reserva
                  </span>
                  <b>
                    {" "}
                    <span style={{ fontSize: "2rem", lineHeight: "0.5" }}>
                      {" "}
                      &#128232;{" "}
                    </span>
                  </b>{" "}
                </h3>
                <div style={{ textAlign: "center", marginBottom: 10 }}>
                  <img
                    className="image-logo"
                    src={
                      this.state.image_path !== ""
                        ? this.state.image_path
                        : "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBg8IBwgSExASFRIQEQ8SEA8VDRUQFREWFhcVFRUYHSggGBolGxUVITEhJSk3Li4uFx8zODM4NygtLisBCgoKDQ0OGg0PFzgfHRk3Lis3Ky0tNy0rLTc3Ky0rLSsrLS0tKy43LS0tKzctLTItNzctNysrLTcrLSstMi03K//AABEIAOEA4QMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIHA//EAB4QAQEBAQACAgMAAAAAAAAAAAABETEhQRJxAlFh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwDuIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAF8Qib+iaCiSqAAAAAAAAAAAAAAAAAAAAAAAAAlUBJxak32oJL43DfCgJ3qp5PsD2qZVAAAAAAAAAAAAAAAAAAAAAKAMzeltXIXgpuTaSy8rF/C/HP16rHw+P5bufycFmPdPOk4oyJ24rGeRWxJxRAAAAAAAAAAAAAAAAAAAAAAE9mqAnS8UBJd4k7xpLcBL8vSzb0+iTBVAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//Z"
                    }
                  />
                </div>

                <div style={{ textAlign: "center" }}>
                  <span>&#128274; Pago seguro</span>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img className="pay-logo" src={"/redenlace-logo.png"} />
                  <div style={{ width: 14 }}></div>
                  <img className="pay-logo" src={"/sip-logo.png"} />
                </div>
              </div>
            )}
          </div>
        </div>
        {/*
        <form
          id="formularioX"
          action={"/api/v1/pago-satisfactorio/${req.params.qr_id}"}
          method="post"
          style={{ display: "none" }}
        ></form>
         */}
      </>
    );
  }
}
export default Home;
